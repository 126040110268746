import { useEffect, useRef, useState } from "react";
import mushroom from "viettoc-api";
import not_found from "assets/images/not-found.jpg";

const Other = () => {
	const [showStatus, setShowStatus] = useState("");

	const checkCodeQr = async () => {
		if (!window.location.pathname) {
			setShowStatus("khong_ton_tai");
			return;
		}
		setShowStatus("dang_xu_li");
		try {
			const searchParams = new URLSearchParams(window.location.search);

			const parameters: Record<string, string> = {};
			const keys = searchParams.keys();
			let cursor = keys.next();
			while (cursor.done === false) {
				const value = searchParams.get(cursor.value);
				if (value !== null) parameters[cursor.value] = value;
				cursor = keys.next();
			}

			const response = await mushroom.qr_scan.get_redirect_urlAsync({
				body: {
					code: window.location.pathname.substring(1),
					parameters,
					qr: window.location.href,
				},
			});
			if (response.result.redirect_url) {
				setShowStatus("dang_dieu_huong");
				window.location.href = response.result.redirect_url;
				return;
			} else {
				setShowStatus("khong_ton_tai");
			}
		} catch (error) {
			setShowStatus("khong_ton_tai");
			console.error("Có lỗi: %o", error);
		}
	};

	useEffect(() => {
		checkCodeQr();
	}, []);

	return (
		<div className="notification-container">
			{showStatus === "khong_ton_tai" && (
				<div>
					<img
						className="image-notification"
						src={not_found}
						alt="Mã QR không tồn tại"
					/>
					<p
						style={{
							marginTop: "10px",
							fontSize: "18px",
							textAlign: "center",
							fontFamily: "Playfair Display",
						}}
					>
						Không tồn tại mã QR này
					</p>
				</div>
			)}
			{showStatus === "dang_dieu_huong" && (
				<div className="text-notification">Đang điều hướng...</div>
			)}
			{showStatus === "dang_xu_li" && (
				<div className="text-notification">Đang xử lý...</div>
			)}
		</div>
	);
};

export default Other;
