import { Suspense } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import routes from "router";
import { Fallback } from "components/Fallback";
import { RouteWithSubRoutes } from "components/RouteWithSubRoutes";
import { IRoute } from "types/IRoute";
import Other from "./Other";
import { Route } from "react-router-dom";

const App = () => {
	return (
		<Suspense fallback={<Fallback />}>
			<BrowserRouter>
				<Switch>
					{routes.map((route: IRoute, i: number) => (
						<RouteWithSubRoutes key={i} {...route} />
					))}
					<Route path="*" component={Other}></Route>
				</Switch>
			</BrowserRouter>
		</Suspense>
	);
};

export default App;
