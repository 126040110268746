import mushroom, {
	defineAsyncResource,
	defineAsyncView,
	fireEvent,
	createRestfulAsyncFunction,
	IdType,
	IMushroom,
	Mushroom,
	MushroomRequest,
	MushroomRequestSetting,
	MushroomRestfulRequest,
	MushroomResponse,
	MushroomResponseDataType,
	MushroomResourceBase,
	MushroomListResource,
	MushroomFindByIdResource,
	MushroomCreateResource,
	MushroomBatchCreateResource,
	MushroomUpdateResource,
	MushroomBatchUpdateResource,
	MushroomPartialUpdateResource,
	MushroomDeleteResource,
	MushroomBatchDeleteResource,
	MushroomExtensibleResource,
	MushroomViewFunction,
	MushroomError,
	IMushroomOfflineResponse,
	MushroomOfflineResponse,
	MushroomOfflineResponseState,
} from "mushroomjs";
import * as AuthExtension from "mushroomjs-auth";
import * as FileExtension from "mushroomjs-file";
export * from "mushroomjs";
export const extensions = {
	File: FileExtension,
	Auth: AuthExtension,
};

export type ISODateValue = string | Date;
export type IdValue = string;

export interface Dang_ky {
	id?: IdValue;
	ten_dong_ho?: string;
	quoc_gia_id?: IdValue;
	tinh_id?: IdValue;
	huyen_id?: IdValue;
	xa_id?: IdValue;
	dia_chi?: string;
	khoang_dinh?: {
		tu?: number;
		den?: number;
	};
	xung_ho?: string;
	ho_ten?: string;
	trong_ho?: boolean;
	dien_thoai?: string;
	email?: string;
	ma_gioi_thieu?: string;
	trang_thai?: string;
	li_do_tu_choi?: string;
	gui_mail_tu_choi?: boolean;
	dong_ho_id?: IdValue;
	thoi_diem_xet_duyet?: ISODateValue;
	nguoi_xet_duyet?: IdValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Dang_ky$ViewsResource_lich_su_dang_kyView_Parameters {
	dien_thoai: string;
	ten_dong_ho: string;
}
export interface Dang_ky$ViewsResource_lich_su_dang_kyView_Result {
	thoi_diem_tao: ISODateValue;
}
export interface Dang_ky$ViewsResource_thong_ke_so_luong_dong_hoView_SystemAdmin_SalesManagerParameters {}
export interface Dang_ky$ViewsResource_thong_ke_so_luong_dong_hoView_SystemAdmin_SalesManagerResult {
	name: string;
	count: number;
}
export interface Dang_ky$ViewsResource {
	views: {
		lich_su_dang_kyAsync: MushroomViewFunction<
			Dang_ky$ViewsResource_lich_su_dang_kyView_Result,
			Dang_ky$ViewsResource_lich_su_dang_kyView_Parameters
		>;
		thong_ke_so_luong_dong_hoAsync: MushroomViewFunction<
			Dang_ky$ViewsResource_thong_ke_so_luong_dong_hoView_SystemAdmin_SalesManagerResult,
			Dang_ky$ViewsResource_thong_ke_so_luong_dong_hoView_SystemAdmin_SalesManagerParameters
		>;
	};
}
defineAsyncResource<Dang_ky>({
	name: "dang_ky",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		createOne: {},
	},
	views: {
		lich_su_dang_ky: { clientCache: true },
		thong_ke_so_luong_dong_ho: { clientCache: true },
	},
});

export interface Dang_ky_phan_hoi {
	id?: IdValue;
	dang_ky_id?: IdValue;
	noi_dung?: string;
	nguoi_gui?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Dang_ky_phan_hoi>({
	name: "dang_ky_phan_hoi",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Dang_ky_ghi_chu {
	id?: IdValue;
	dang_ky_id?: IdValue;
	noi_dung?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Dang_ky_ghi_chu>({
	name: "dang_ky_ghi_chu",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Dong_ho {
	id?: IdValue;
	ten?: string;
	dia_chi?: {
		quoc_gia_id?: IdValue;
		tinh_id?: IdValue;
		huyen_id?: IdValue;
		xa_id?: IdValue;
		dia_chi?: string;
	};
	khoang_dinh?: {
		tu?: number;
		den?: number;
	};
	ngay_gio_to?: string;
	dia_chi_mo_to?: string;
	nguoi_quan_lys?: {
		nguoi_dung_id?: IdValue;
		dien_thoai?: string;
		quyen?: string;
		nhanh_ids?: IdValue[];
		quyen_duyet?: boolean;
	}[];
	lich_su?: string;
	lich_su_file_id?: IdValue;
	dai_ly_id?: IdValue;
	dai_ly_khoi_tao_id?: IdValue;
	hieu_luc?: boolean;
	trang_thai?: string;
	goi_dich_vu?: {
		goi_dich_vu_id?: IdValue;
		ten_goi?: string;
	};
	thue_bao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
	thong_tin_tong_hop?: {
		dung_luong_luu_tru?: {
			han_muc?: number;
			gia_tri?: number;
			so_luong_file?: number;
		};
	};
}
export interface Dong_ho$CustomMethodResource {
	importCayGiaPhaAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	importCayGiaPhaJsonAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	dangKyDongHoAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	guiLaiMaKichHoatAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	xoaDuLieuDongHoAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	traoQuyenAdminAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	tuBoQuyenModAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	kiemTraDuLieuDongHoAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
export interface Dong_ho$ViewsResource_thong_ke_cay_gia_phaView_FamilyUserParameters {
	dong_ho_id: IdValue;
}
export interface Dong_ho$ViewsResource {
	views: {
		thong_ke_cay_gia_phaAsync: MushroomViewFunction<
			any,
			Dong_ho$ViewsResource_thong_ke_cay_gia_phaView_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Dong_ho>({
	name: "dong_ho",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		_raw_http_method_importCayGiaPha: {},
		_raw_http_method_importCayGiaPhaJson: {},
		_raw_http_method_dangKyDongHo: {},
		_raw_http_method_guiLaiMaKichHoat: {},
		_raw_http_method_xoaDuLieuDongHo: {},
		_raw_http_method_traoQuyenAdmin: {},
		_raw_http_method_tuBoQuyenMod: {},
		_raw_http_method_kiemTraDuLieuDongHo: {},
	},
	views: { thong_ke_cay_gia_pha: { clientCache: true } },
});

export interface Dong_ho_ghi_chu {
	id?: IdValue;
	dai_ly_id?: IdValue;
	dong_ho_id?: IdValue;
	noi_dung?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Dong_ho_ghi_chu>({
	name: "dong_ho_ghi_chu",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Thue_bao {
	id?: IdValue;
	goi_dich_vu_id?: IdValue;
	dong_ho_id?: IdValue;
	don_hang_id?: IdValue;
	don_hang_ids?: IdValue[];
	thoi_gian_hieu_luc?: {
		tu?: ISODateValue;
		den?: ISODateValue;
	};
	thong_tin_goi?: {
		ten_goi?: string;
		gia_niem_yet?: number;
		danh_sach_chuc_nang?: {
			ma?: string;
			ten?: string;
			gia_tri?: number;
		}[];
	};
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Thue_bao>({
	name: "thue_bao",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
	},
	views: {},
});

export interface Nguoi_dung {
	id?: IdValue;
	quan_he?: {
		cha_me_1_id?: IdValue;
		cha_me_2_id?: IdValue;
		doi_truoc_1_ids?: IdValue[];
		doi_truoc_3_ids?: IdValue[];
		danh_sach_vo_chong?: {
			node_id?: IdValue;
			li_di?: boolean;
			da_mat?: boolean;
		}[];
		danh_sach_con?: {
			node_id?: IdValue;
			con_nuoi?: boolean;
		}[];
	};
	ten?: {
		xung_ho?: string;
		ho_ten?: string;
		ho_dem?: string;
		ten?: string;
		thuong_goi?: string;
		biet_hieu?: string;
		huy?: string;
		tu?: string;
		hieu?: string;
		thuy?: string;
	};
	avatar_id?: IdValue;
	gioi_tinh?: string;
	sinh?: {
		phut?: number;
		gio?: number;
		ngay_sinh?: ISODateValue;
		ngay_am?: number;
		ngay_duong?: number;
		thang_am?: number;
		thang_nhuan?: boolean;
		thang_duong?: number;
		nam_am?: number;
		nam_duong?: number;
		noi_sinh?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
		can_chi?: {
			gio?: string;
			ngay?: string;
			thang?: string;
			nam?: string;
		};
	};
	mat?: {
		da_mat?: boolean;
		phut?: number;
		gio?: number;
		ngay_mat?: ISODateValue;
		ngay_am?: number;
		ngay_duong?: number;
		thang_am?: number;
		thang_nhuan?: boolean;
		thang_duong?: number;
		nam_am?: number;
		nam_duong?: number;
		can_chi?: {
			gio?: string;
			ngay?: string;
			thang?: string;
			nam?: string;
		};
		mo_id?: IdValue;
		loai_tang?: string;
	};
	dia_chi?: {
		quoc_gia_id?: IdValue;
		tinh_id?: IdValue;
		huyen_id?: IdValue;
		xa_id?: IdValue;
		dia_chi?: string;
		nguyen_quan?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
			dia_danh_cu?: string;
		};
		que_quan?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
			dia_danh_cu?: string;
		};
		thuong_tru?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
			dia_danh_cu?: string;
		};
		tam_tru?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
	};
	thong_tin_lien_lac?: {
		dien_thoai?: string;
		email?: string;
		facebook?: string;
		facebook_id?: string;
		zalo?: {
			id?: string;
			link?: string;
			refresh_tokens?: {
				value?: string;
				expired_time?: ISODateValue;
			}[];
		};
		skype_id?: string;
	};
	giay_to?: {
		cmnd?: {
			so?: string;
			noi_cap?: string;
			ngay_cap?: ISODateValue;
			anh_mat_truoc_id?: IdValue;
			anh_mat_sau_id?: IdValue;
		};
		cccd?: {
			so?: string;
			noi_cap?: string;
			ngay_cap?: ISODateValue;
			ngay_het_han?: ISODateValue;
			qr?: string;
			anh_mat_truoc_id?: IdValue;
			anh_mat_sau_id?: IdValue;
		};
		passport?: {
			so?: string;
			noi_cap?: string;
			ngay_cap?: ISODateValue;
			anh_id?: IdValue;
		};
	};
	hoc_van?: {
		hoc_ham?: string;
		hoc_vi?: string;
		qua_trinh?: {
			tu?: ISODateValue;
			tu_nam?: number;
			den?: ISODateValue;
			den_nam?: number;
			cap_hoc?: string;
			ten_truong?: string;
			da_tot_nghiep?: boolean;
		}[];
	};
	cong_viec?: {
		nghe_nghiep?: string;
		noi_cong_tac?: string;
		chuc_vu?: string;
	};
	sinh_ton?: {
		gioi_tinh?: string;
		chieu_cao?: number;
		nhom_mau?: string;
		benh_di_truyen?: string[];
	};
	ton_giao_id?: IdValue;
	privacy?: {
		privateFields?: string[];
	};
	user_id?: IdValue;
	kich_hoat?: boolean;
	thoi_diem_kich_hoat?: ISODateValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Nguoi_dung$CustomMethodResource {
	kiemTraSoDienThoaiAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	layDanhSachDongHoAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	layDanhSachNguoiDungTuDongHoIdsAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	mergeNguoiDungAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Nguoi_dung>({
	name: "nguoi_dung",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		deleteOne: {},
		_raw_http_method_kiemTraSoDienThoai: {},
		_raw_http_method_layDanhSachDongHo: {},
		_raw_http_method_layDanhSachNguoiDungTuDongHoIds: {},
		_raw_http_method_mergeNguoiDung: {},
	},
	views: {},
});

export interface Nguoi_dung_cau_hinh {
	id?: IdValue;
	dong_ho_id?: IdValue;
	nguoi_dung_id?: IdValue;
	hien_thi?: {
		dia_chi?: {
			quoc_gia?: boolean;
			tinh?: boolean;
			huyen?: boolean;
			xa?: boolean;
			dia_chi?: boolean;
		};
		thong_tin_lien_lac?: {
			dien_thoai?: boolean;
			email?: boolean;
			facebook?: boolean;
			zalo?: boolean;
			skype_id?: boolean;
		};
		hoc_van?: {
			hoc_ham?: boolean;
			hoc_vi?: boolean;
			qua_trinh?: boolean;
		};
		cong_viec?: {
			nghe_nghiep?: boolean;
			noi_cong_tac?: boolean;
			chuc_vu?: boolean;
		};
		ton_giao?: {
			ton_giao?: boolean;
		};
	};
	user_id?: IdValue;
}
defineAsyncResource<Nguoi_dung_cau_hinh>({
	name: "nguoi_dung_cau_hinh",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface User {
	id?: IdValue;
	account?: string;
	password?: string;
	disabled?: boolean;
	roles?: string[];
	tokens?: {
		value?: string;
		time?: ISODateValue;
	}[];
}
defineAsyncResource<User>({
	name: "user",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Profile {
	id?: IdValue;
	ten?: string;
	avatar_id?: IdValue;
	gioi_tinh?: string;
	email?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Profile>({
	name: "profile",
	actions: { findById: { clientCache: true }, updatePartially: {} },
	views: {},
});

export interface Phien_kich_hoat {
	id?: IdValue;
	dien_thoai_request?: string;
	nguoi_dung_id_request?: IdValue;
	firebase_token?: string;
	dien_thoai_confirm?: string;
	ten_nguoi_confirm?: string;
	trang_thai?: string;
	code?: string;
	otp?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Phien_kich_hoat$CustomMethodResource {
	kiemTraSoDienThoaiAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	requestOtpAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	kichHoatTaiKhoanAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Phien_kich_hoat>({
	name: "phien_kich_hoat",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		createOne: {},
		deleteOne: {},
		_raw_http_method_kiemTraSoDienThoai: {},
		_raw_http_method_requestOtp: {},
		_raw_http_method_kichHoatTaiKhoan: {},
	},
	views: {},
});

export interface Phien_kich_hoat_3 {
	id?: IdValue;
	phuong_thuc?: string;
	dien_thoai?: string;
	firebase_token?: string;
	provider_code?: string;
	provider_status?: string;
	call_status?: string;
	type?: string;
	trang_thai?: string;
	dien_thoai_nhan?: string;
	code?: string;
	thoi_diem_tao?: ISODateValue;
}
export interface Phien_kich_hoat_3$CustomMethodResource {
	khoi_tao_phienAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	gui_lai_ma_kich_hoatAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	kich_hoat_tai_khoanAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	khoi_tao_phien_voice_otpAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	get_phuong_thuc_kich_hoatAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Phien_kich_hoat_3>({
	name: "phien_kich_hoat_3",
	actions: {
		findById: { clientCache: true },
		_raw_http_method_khoi_tao_phien: {},
		_raw_http_method_gui_lai_ma_kich_hoat: {},
		_raw_http_method_kich_hoat_tai_khoan: {},
		_raw_http_method_khoi_tao_phien_voice_otp: {},
		_raw_http_method_get_phuong_thuc_kich_hoat: {},
	},
	views: {},
});

export interface Thiet_bi_kich_hoat {
	id?: IdValue;
	ten?: string;
	so_dien_thoai?: string;
	co_hieu_luc?: boolean;
	secret_key?: string;
}
export interface Thiet_bi_kich_hoat$CustomMethodResource {
	save_call_logAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	save_smsAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	notify_aliveAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Thiet_bi_kich_hoat>({
	name: "thiet_bi_kich_hoat",
	actions: {
		_raw_http_method_save_call_log: {},
		_raw_http_method_save_sms: {},
		_raw_http_method_notify_alive: {},
	},
	views: {},
});

export interface Phien_pc {
	id?: IdValue;
	qr?: string;
	trang_thai?: string;
	user_id?: IdValue;
	nguoi_dung_id?: IdValue;
	dong_ho_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
}
export interface Phien_pc$CustomMethodResource {
	khoi_tao_phienAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	lay_tokenAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	xac_thucAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Phien_pc>({
	name: "phien_pc",
	actions: {
		_raw_http_method_khoi_tao_phien: {},
		_raw_http_method_lay_token: {},
		_raw_http_method_xac_thuc: {},
	},
	views: {},
});

export interface Node_gia_pha {
	id?: IdValue;
	duoc_duyet?: boolean;
	gui_yeu_cau?: boolean;
	node_to?: boolean;
	nguoi_dung_id?: IdValue;
	kich_hoat?: boolean;
	dong_ho_id?: IdValue;
	trong_ho?: boolean;
	quan_he?: {
		cha_me_1_id?: IdValue;
		cha_me_2_id?: IdValue;
		doi_truoc_1_ids?: IdValue[];
		doi_truoc_3_ids?: IdValue[];
		danh_sach_vo_chong?: {
			node_id?: IdValue;
			li_di?: boolean;
			da_mat?: boolean;
		}[];
		danh_sach_con?: {
			node_id?: IdValue;
			con_nuoi?: boolean;
		}[];
	};
	ten?: {
		ho_ten?: string;
		ho_dem?: string;
		ten?: string;
		thuong_goi?: string;
		biet_hieu?: string;
		huy?: string;
		tu?: string;
		hieu?: string;
		thuy?: string;
	};
	avatar_id?: IdValue;
	gioi_tinh?: string;
	sinh?: {
		ngay_sinh?: ISODateValue;
		ngay_am?: number;
		ngay_duong?: number;
		thang_am?: number;
		thang_nhuan?: boolean;
		thang_duong?: number;
		nam_am?: number;
		nam_duong?: number;
		can_chi?: {
			ngay?: string;
			thang?: string;
			nam?: string;
		};
		raw?: string;
	};
	mat?: {
		da_mat?: boolean;
		ngay_mat?: ISODateValue;
		ngay_am?: number;
		ngay_duong?: number;
		thang_am?: number;
		thang_nhuan?: boolean;
		thang_duong?: number;
		nam_am?: number;
		nam_duong?: number;
		can_chi?: {
			ngay?: string;
			thang?: string;
			nam?: string;
		};
		mo_id?: IdValue;
		raw?: string;
	};
	ky_su?: string;
	src_id?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
	thoi_diem_duyet?: ISODateValue;
	nguoi_duyet_id?: IdValue;
}
export interface Node_gia_pha$CustomMethodResource {
	thongTinNodeAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	cayGiaPhaAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	themNodeAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	boDuyetNodeAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	xoaNodeAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	xoaNodeTestAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Node_gia_pha>({
	name: "node_gia_pha",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		_raw_http_method_thongTinNode: {},
		_raw_http_method_cayGiaPha: {},
		_raw_http_method_themNode: {},
		_raw_http_method_boDuyetNode: {},
		_raw_http_method_xoaNode: {},
		_raw_http_method_xoaNodeTest: {},
	},
	views: {},
});

export interface Node_gia_pha_request_field {
	id?: IdValue;
	gui_yeu_cau?: boolean;
	dong_ho_id?: IdValue;
	node_gia_pha_id?: IdValue;
	ten_truong?: string;
	gia_tri?: string;
	kieu_du_lieu?: string;
	trang_thai?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Node_gia_pha_request_field>({
	name: "node_gia_pha_request_field",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Su_kien {
	id?: IdValue;
	dong_ho_id?: IdValue;
	album_id?: IdValue;
	anh_dai_dien_id?: IdValue;
	tieu_de?: string;
	thoi_diem?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
}
defineAsyncResource<Su_kien>({
	name: "su_kien",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Album {
	id?: IdValue;
	dong_ho_id?: IdValue;
	anh_dai_dien_id?: IdValue;
	tieu_de?: string;
	mo_ta?: string;
	thoi_diem?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
}
defineAsyncResource<Album>({
	name: "album",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Anh_album {
	id?: IdValue;
	dong_ho_id?: IdValue;
	album_id?: IdValue;
	file_id?: IdValue;
	mo_ta?: string;
	tags?: {
		node_id?: IdValue;
		time?: ISODateValue;
		node_tao_id?: IdValue;
		toa_do?: {
			x?: number;
			y?: number;
		};
	}[];
	nguoi_tao_id?: IdValue;
	node_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
}
export interface Anh_album$CustomMethodResource {
	tagAsync: (rawRequest: MushroomRequest) => Promise<MushroomResponse<any>>;
}
export interface Anh_album$ViewsResource_group_by_dateView_FamilyUserParameters {
	dong_ho_id: IdValue;
	fromDate?: ISODateValue;
	toDate?: ISODateValue;
	limit?: number;
	groupLimit?: number;
}
export interface Anh_album$ViewsResource_mine_group_by_dateView_FamilyUserParameters {
	dong_ho_id: IdValue;
	fromDate?: ISODateValue;
	toDate?: ISODateValue;
	limit?: number;
	groupLimit?: number;
}
export interface Anh_album$ViewsResource_tag_group_by_dateView_FamilyUserParameters {
	dong_ho_id: IdValue;
	fromDate?: ISODateValue;
	toDate?: ISODateValue;
	limit?: number;
	groupLimit?: number;
}
export interface Anh_album$ViewsResource {
	views: {
		group_by_dateAsync: MushroomViewFunction<
			any,
			Anh_album$ViewsResource_group_by_dateView_FamilyUserParameters
		>;
		mine_group_by_dateAsync: MushroomViewFunction<
			any,
			Anh_album$ViewsResource_mine_group_by_dateView_FamilyUserParameters
		>;
		tag_group_by_dateAsync: MushroomViewFunction<
			any,
			Anh_album$ViewsResource_tag_group_by_dateView_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Anh_album>({
	name: "anh_album",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		_raw_http_method_tag: {},
	},
	views: {
		group_by_date: { clientCache: true },
		mine_group_by_date: { clientCache: true },
		tag_group_by_date: { clientCache: true },
	},
});

export interface Bang_vang {
	id?: IdValue;
	dong_ho_id?: IdValue;
	danh_muc?: string;
	tieu_de?: string;
	thanh_tich?: string;
	noi_dung?: string;
	tags?: {
		node_id?: IdValue;
		time?: ISODateValue;
		node_tao_id?: IdValue;
	}[];
	node_tao_id?: IdValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
}
defineAsyncResource<Bang_vang>({
	name: "bang_vang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Tin_khuyen_hoc {
	id?: IdValue;
	dong_ho_id?: IdValue;
	anh_dai_dien_id?: IdValue;
	tieu_de?: string;
	noi_dung?: string;
	ghim?: boolean;
	tags?: {
		node_id?: IdValue;
		time?: ISODateValue;
		node_tao_id?: IdValue;
		toa_do?: {
			x?: number;
			y?: number;
		};
	}[];
	node_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Tin_khuyen_hoc>({
	name: "tin_khuyen_hoc",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Quy_khuyen_hoc {
	id?: IdValue;
	dong_ho_id?: IdValue;
	loai?: string;
	tien?: number;
	noi_dung?: string;
	node_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
export interface Quy_khuyen_hoc$ViewsResource_get_yearsView_FamilyUserParameters {
	dong_ho_id: IdValue;
}
export interface Quy_khuyen_hoc$ViewsResource {
	views: {
		get_yearsAsync: MushroomViewFunction<
			any,
			Quy_khuyen_hoc$ViewsResource_get_yearsView_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Quy_khuyen_hoc>({
	name: "quy_khuyen_hoc",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: { get_years: { clientCache: true } },
});

export interface Cong_duc {
	id?: IdValue;
	dong_ho_id?: IdValue;
	anh_dai_dien_id?: IdValue;
	tieu_de?: string;
	noi_dung?: string;
	ghim?: boolean;
	muc_tieu?: {
		ngay_muc_tieu?: ISODateValue;
		tien_muc_tieu?: number;
	};
	quy?: {
		thu_quy_id?: IdValue;
		thu_quy_node_id?: IdValue;
		phuong_thuc_dong_gop?: string;
		danh_sach_tai_khoan?: {
			loai?: string;
			so_tai_khoan?: string;
			ten_chu_tai_khoan?: string;
			ten_don_vi_cung_cap?: string;
			chi_nhanh?: string;
			swift?: string;
		}[];
	};
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	node_tao_id?: IdValue;
}
export interface Cong_duc$ViewsResource_get_amountsView_FamilyUserParameters {
	dong_ho_id: IdValue;
	cong_duc_ids: IdValue[];
}
export interface Cong_duc$ViewsResource {
	views: {
		get_amountsAsync: MushroomViewFunction<
			any,
			Cong_duc$ViewsResource_get_amountsView_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Cong_duc>({
	name: "cong_duc",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: { get_amounts: { clientCache: true } },
});

export interface Cong_duc_cam_ket {
	id?: IdValue;
	dong_ho_id?: IdValue;
	cong_duc_id?: IdValue;
	node_id?: IdValue;
	tien?: number;
	tien_goc?: number;
	ghi_chu?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Cong_duc_cam_ket>({
	name: "cong_duc_cam_ket",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Cong_duc_dong_gop {
	id?: IdValue;
	dong_ho_id?: IdValue;
	cong_duc_id?: IdValue;
	node_id?: IdValue;
	cam_ket_id?: IdValue;
	tien?: number;
	ghi_chu?: string;
	an_danh?: boolean;
	ngay_dong_gop?: ISODateValue;
	loai_hinh_dong_gop?: string;
	anh_bien_lai_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Cong_duc_dong_gop>({
	name: "cong_duc_dong_gop",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Vinh_hang_vien {
	id?: IdValue;
	duoc_duyet?: boolean;
	node_id?: IdValue;
	dong_ho_id?: IdValue;
	mai_tang?: {
		hinh_thuc?: string;
		thoi_gian?: {
			ngay_mai_tang?: ISODateValue;
			ngay_am?: number;
			ngay_duong?: number;
			thang_am?: number;
			thang_nhuan?: boolean;
			thang_duong?: number;
			nam_am?: number;
			nam_duong?: number;
			can_chi?: {
				ngay?: string;
				thang?: string;
				nam?: string;
			};
		};
		dia_chi?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
		mo?: {
			nghia_trang?: string;
			vi_tri?: string;
			kinh_do?: number;
			vi_do?: number;
		};
	};
	an_tang?: {
		thoi_gian?: {
			ngay_an_tang?: ISODateValue;
			ngay_am?: number;
			ngay_duong?: number;
			thang_am?: number;
			thang_nhuan?: boolean;
			thang_duong?: number;
			nam_am?: number;
			nam_duong?: number;
			can_chi?: {
				ngay?: string;
				thang?: string;
				nam?: string;
			};
		};
		dia_chi?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
		mo?: {
			nghia_trang?: string;
			vi_tri?: string;
			kinh_do?: number;
			vi_do?: number;
		};
	};
	ly_do_mat?: string;
	file_ids?: IdValue[];
	ghi_chu?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Vinh_hang_vien$CustomMethodResource {
	isEditableVinhHangVienAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	getVinhHangVienByNodeIdAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	getVinhHangViensByDongHoIdAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Vinh_hang_vien>({
	name: "vinh_hang_vien",
	actions: {
		updatePartially: {},
		createOne: {},
		_raw_http_method_isEditableVinhHangVien: {},
		_raw_http_method_getVinhHangVienByNodeId: {},
		_raw_http_method_getVinhHangViensByDongHoId: {},
	},
	views: {},
});

export interface Don_hang {
	id?: IdValue;
	ma_don_hang?: string;
	dong_ho?: {
		dong_ho_id?: IdValue;
		ten?: string;
	};
	nguoi_dat_hang?: {
		nguoi_dung_id?: IdValue;
		xung_ho?: string;
		ten?: string;
	};
	loai?: string;
	goi_dich_vu?: {
		goi_id?: IdValue;
		ten_goi?: string;
		don_gia?: number;
		so_luong_chu_ky?: number;
		ten_chu_ky?: string;
	};
	vas?: {
		vas_id?: IdValue;
		ten?: string;
		don_gia?: number;
		so_luong?: number;
		don_vi?: string;
		so_luong_chu_ky?: number;
		ten_chu_ky?: string;
	}[];
	khuyen_mai?: {
		ma_giam_gia?: string;
		noi_dung?: string;
	};
	gia?: {
		niem_yet?: number;
		giam_gia?: number;
		thanh_tien?: number;
	};
	trang_thai?: string;
	thoi_diem_tao?: ISODateValue;
}
defineAsyncResource<Don_hang>({
	name: "don_hang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Phuong_thuc_thanh_toan {
	id?: IdValue;
	ma?: string;
	ten?: string;
	hieu_luc?: {
		an?: boolean;
		tu?: ISODateValue;
		den?: ISODateValue;
	};
}
defineAsyncResource<Phuong_thuc_thanh_toan>({
	name: "phuong_thuc_thanh_toan",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Thanh_toan {
	id?: IdValue;
	don_hang?: {
		don_hang_id?: IdValue;
		ma?: string;
		tien?: number;
	};
	dong_ho?: {
		dong_ho_id?: IdValue;
		ten?: string;
	};
	nguoi_thanh_toan_id?: IdValue;
	phuong_thuc_thanh_toan?: {
		pttt_id?: IdValue;
		ten?: string;
	};
	phi?: {
		khach?: number;
		he_thong?: number;
	};
	tien_thanh_toan?: number;
	trang_thai?: string;
	thoi_diem?: {
		tao?: ISODateValue;
		thanh_toan?: ISODateValue;
		huy?: ISODateValue;
	};
}
defineAsyncResource<Thanh_toan>({
	name: "thanh_toan",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Goi_dich_vu {
	id?: IdValue;
	ten?: string;
	thu_tu?: number;
	gia_tri?: string;
	cong_khai?: boolean;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Goi_dich_vu>({
	name: "goi_dich_vu",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Tham_so_goi_dich_vu {
	id?: IdValue;
	goi_id?: IdValue;
	ma?: string;
	ten?: string;
	cong_khai?: boolean;
	thu_tu?: number;
	gia_tri?: string;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Tham_so_goi_dich_vu>({
	name: "tham_so_goi_dich_vu",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Cau_hoi_thuong_gap {
	id?: IdValue;
	hien_thi?: boolean;
	phan_loai?: string[];
	thu_tu?: number;
	tieu_de?: string;
	noi_dung?: string;
	tags?: string[];
	thoi_diem_tao?: ISODateValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Cau_hoi_thuong_gap>({
	name: "cau_hoi_thuong_gap",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Chinh_sach {
	id?: IdValue;
	thu_tu?: number;
	hien_thi?: boolean;
	phan_loai?: string;
	tieu_de?: string;
	mo_ta?: string;
	noi_dung?: string;
	thoi_diem_tao?: ISODateValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Chinh_sach>({
	name: "chinh_sach",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Thong_ke {
	id?: IdValue;
}
export interface Thong_ke$ViewsResource_thong_ke_du_lieuView_SystemAdminParameters {}
export interface Thong_ke$ViewsResource_bieu_do_nodeView_SystemAdminParameters {}
export interface Thong_ke$ViewsResource {
	views: {
		thong_ke_du_lieuAsync: MushroomViewFunction<
			any,
			Thong_ke$ViewsResource_thong_ke_du_lieuView_SystemAdminParameters
		>;
		bieu_do_nodeAsync: MushroomViewFunction<
			any,
			Thong_ke$ViewsResource_bieu_do_nodeView_SystemAdminParameters
		>;
	};
}
defineAsyncResource<Thong_ke>({
	name: "thong_ke",
	actions: {},
	views: {
		thong_ke_du_lieu: { clientCache: true },
		bieu_do_node: { clientCache: true },
	},
});

export interface Ldp_cau_hinh {
	id?: IdValue;
	ten?: string;
	gia_tri?: string;
	cong_khai?: boolean;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Ldp_cau_hinh>({
	name: "ldp_cau_hinh",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Ldp_slide {
	id?: IdValue;
	tieu_de?: string;
	gioi_thieu?: string;
	anh_id?: IdValue;
	anh_nho_id?: IdValue;
	video_id?: IdValue;
	duong_dan?: string;
	thu_tu?: number;
	hien_thi?: {
		an?: boolean;
		hieu_luc_tu?: ISODateValue;
		hieu_luc_den?: ISODateValue;
		dieu_kien?: string[];
	};
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Ldp_slide>({
	name: "ldp_slide",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Ldp_danh_gia {
	id?: IdValue;
	tieu_de?: string;
	gioi_thieu?: string;
	dong_ho?: {
		dong_ho_id?: IdValue;
		ten?: string;
		dia_chi?: string;
	};
	ten_nguoi_danh_gia?: string;
	anh_id?: IdValue;
	video_id?: IdValue;
	duong_dan?: string;
	thu_tu?: number;
	hien_thi?: {
		an?: boolean;
		hieu_luc_tu?: ISODateValue;
		hieu_luc_den?: ISODateValue;
		dieu_kien?: string[];
	};
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Ldp_danh_gia>({
	name: "ldp_danh_gia",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Ldp_loi_nhan {
	id?: IdValue;
	ho_ten?: string;
	dien_thoai?: string;
	email?: string;
	noi_dung?: string;
	loai_loi_nhan?: string;
	trang_thai?: string;
	file_dinh_kem?: IdValue;
	thoi_diem_tao?: ISODateValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
	ip?: string;
	isDeleted?: boolean;
}
defineAsyncResource<Ldp_loi_nhan>({
	name: "ldp_loi_nhan",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Ldp_loi_nhan_phan_hoi {
	id?: IdValue;
	loi_nhan_id?: IdValue;
	noi_dung?: string;
	nguoi_gui?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
}
defineAsyncResource<Ldp_loi_nhan_phan_hoi>({
	name: "ldp_loi_nhan_phan_hoi",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Ldp_thong_ke {
	id?: IdValue;
}
export interface Ldp_thong_ke$ViewsResource_chi_so_phat_trienView_Parameters {}
export interface Ldp_thong_ke$ViewsResource {
	views: {
		chi_so_phat_trienAsync: MushroomViewFunction<
			any,
			Ldp_thong_ke$ViewsResource_chi_so_phat_trienView_Parameters
		>;
	};
}
defineAsyncResource<Ldp_thong_ke>({
	name: "ldp_thong_ke",
	actions: {},
	views: { chi_so_phat_trien: { clientCache: true } },
});

export interface Ldp_bai_viet {
	id?: IdValue;
	thu_tu?: number;
	uu_tien?: boolean;
	tieu_de?: string;
	mo_ta_ngan_gon?: string;
	anh_id?: IdValue;
	noi_dung?: string;
	tags?: string[];
	bai_viet_ids?: IdValue[];
	hien_thi?: boolean;
	but_danh?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Ldp_bai_viet$ViewsResource_get_so_luong_bai_vietView_SystemAdmin_ContentAdminParameters {
	year?: number;
	month?: number;
	week?: number;
	minDayOfMonth?: number;
	maxDayOfMonth?: number;
}
export interface Ldp_bai_viet$ViewsResource {
	views: {
		get_so_luong_bai_vietAsync: MushroomViewFunction<
			any,
			Ldp_bai_viet$ViewsResource_get_so_luong_bai_vietView_SystemAdmin_ContentAdminParameters
		>;
	};
}
defineAsyncResource<Ldp_bai_viet>({
	name: "ldp_bai_viet",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: { get_so_luong_bai_viet: { clientCache: true } },
});

export interface Quoc_gia {
	id?: IdValue;
	index?: number;
	ten_quoc_gia?: {
		vi?: string;
		en?: string;
	};
	ten_quoc_tich?: {
		vi?: string;
		en?: string;
	};
	ma?: {
		alpha_2?: string;
		alpha_3?: string;
		numeric?: string;
	};
	ma_dien_thoai?: string[];
}
defineAsyncResource<Quoc_gia>({
	name: "quoc_gia",
	actions: {
		findMany: { clientCache: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Province {
	id?: IdValue;
	disabled?: boolean;
	code?: string;
	name?: string;
	index?: number;
	type?: string;
	center?: {
		lng?: number;
		lat?: number;
	};
}
defineAsyncResource<Province>({
	name: "province",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface District {
	id?: IdValue;
	disabled?: boolean;
	code?: string;
	name?: string;
	provinceId?: IdValue;
	index?: number;
	type?: string;
	center?: {
		lng?: number;
		lat?: number;
	};
}
defineAsyncResource<District>({
	name: "district",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Commune {
	id?: IdValue;
	disabled?: boolean;
	districtId?: IdValue;
	name?: string;
	code?: string;
	index?: number;
	type?: string;
	center?: {
		lng?: number;
		lat?: number;
	};
}
defineAsyncResource<Commune>({
	name: "commune",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Ton_giao {
	id?: IdValue;
	thu_tu?: number;
	ten?: string;
}
defineAsyncResource<Ton_giao>({
	name: "ton_giao",
	actions: {
		findMany: { clientCache: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Notification {
	id?: IdValue;
	toUserId?: IdValue;
	content?: {
		title?: string;
		message?: string;
		imageId?: IdValue;
		priority?: string;
		data?: {};
	};
	flags?: {
		sysNoti?: boolean;
		stored?: boolean;
	};
	type?: string;
	from_queue_id?: IdValue;
	isRead?: boolean;
	readTime?: ISODateValue;
	sticky?: boolean;
	unstickyTime?: ISODateValue;
	createdTime?: ISODateValue;
}
export interface Notification$CustomMethodResource {
	mark_all_as_readAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Notification>({
	name: "notification",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		updatePartially: {},
		deleteOne: {},
		_raw_http_method_mark_all_as_read: {},
	},
	views: {},
});

export interface Notification_queue {
	id?: IdValue;
	status?: string;
	source?: string;
	fromUserId?: IdValue;
	dong_ho_id?: IdValue;
	title?: string;
	message?: string;
	priority?: string;
	schedule?: ISODateValue;
	imageId?: IdValue;
	sticky?: boolean;
	unstickyTime?: ISODateValue;
	createdTime?: ISODateValue;
}
defineAsyncResource<Notification_queue>({
	name: "notification_queue",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Firebase {
	id?: IdValue;
	user_id?: IdValue;
	token?: string;
	device?: {
		os?: string;
		pv?: string;
		d?: string;
	};
	app?: {
		av?: string;
		wv?: string;
	};
	created_time?: ISODateValue;
	updated_time?: ISODateValue;
}
defineAsyncResource<Firebase>({
	name: "firebase",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Mail_template {
	id?: IdValue;
	name?: string;
	code?: string;
	content?: string;
	note?: string;
	history?: {
		time?: ISODateValue;
		uid?: IdValue;
	}[];
}
defineAsyncResource<Mail_template>({
	name: "mail_template",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Cau_hinh_thong_bao {
	id?: IdValue;
	user_id?: IdValue;
	ma?: string;
	gia_tri?: boolean;
}
defineAsyncResource<Cau_hinh_thong_bao>({
	name: "cau_hinh_thong_bao",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Cau_hinh_he_thong {
	id?: IdValue;
	ten?: string;
	gia_tri?: string;
	kieu_du_lieu?: string;
	ghi_chu?: string;
	cong_khai?: boolean;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Cau_hinh_he_thong>({
	name: "cau_hinh_he_thong",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		deleteMany: {},
	},
	views: {},
});

export interface Ban_in {
	id?: IdValue;
	trang_thai?: string;
	don_hang?: {
		da_chot_don?: boolean;
		thoi_diem_chot?: ISODateValue;
		nguoi_chot_don_id?: IdValue;
	};
	so_luong?: number;
	dong_ho_id?: IdValue;
	user_id?: IdValue;
	nguoi_dung_id?: IdValue;
	node_id?: IdValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_tao?: ISODateValue;
	lich_su?: {
		thoi_diem?: ISODateValue;
		trang_thai_truoc?: string;
		trang_thai_sau?: string;
		nguoi_chuyen_id?: IdValue;
	}[];
	cau_hinh?: {
		ten_pha_do?: string;
		ten_toc?: string;
		dia_chi?: string;
		in_dau_re?: boolean;
		in_ngay_sinh?: boolean;
		in_ngay_gio?: boolean;
		compact_layout_width?: boolean;
		compact_layout_height?: boolean;
		theme?: string;
		node_an_ids?: IdValue[];
		node_goc_id?: IdValue;
	};
	lien_he?: {
		ho_ten?: string;
		dien_thoai?: string;
		dia_chi?: string;
	};
	ban_mem?: {
		anh_dai_dien_id?: IdValue;
		ban_in_id?: IdValue;
		file_thiet_ke_id?: IdValue;
		file_watermark_id?: IdValue;
	};
	bao_hanh?: {
		bat_dau?: ISODateValue;
		ket_thuc?: ISODateValue;
		thoi_han?: string;
	};
	thong_tin?: {
		so_node?: number;
		so_doi?: number;
	};
}
defineAsyncResource<Ban_in>({
	name: "ban_in",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Qr_scan {
	id?: IdValue;
	code?: string;
	pattern_url?: string;
}
export interface Qr_scan$CustomMethodResource {
	get_redirect_urlAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Qr_scan>({
	name: "qr_scan",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
		_raw_http_method_get_redirect_url: {},
	},
	views: {},
});

export interface Version {
	id?: IdValue;
	platform?: string;
	current?: number;
	min?: number;
	link?: string;
	display?: string;
	releaseNote?: string;
	releaseNotes?: string[];
}
defineAsyncResource<Version>({
	name: "version",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		deleteOne: {},
	},
	views: {},
});

export interface Log_action {
	id?: IdValue;
	ip?: string;
	a?: string;
	q?: string;
	w?: number;
	h?: number;
	udid?: string;
	wdid?: string;
	fv?: string;
	gv?: string;
	t?: ISODateValue;
	uid?: IdValue;
}
defineAsyncResource<Log_action>({
	name: "log_action",
	actions: { createOne: {} },
	views: {},
});

export interface Log_device {
	id?: IdValue;
	ip?: string;
	ua?: string;
	scr?: {
		w?: number;
		h?: number;
		aw?: number;
		ah?: number;
		cd?: number;
		pd?: number;
		oa?: number;
	};
	wnd?: {
		w?: number;
		h?: number;
		dpr?: number;
	};
	extra?: string;
	udid?: string;
	wdid?: string;
	t?: ISODateValue;
	uid?: IdValue;
}
defineAsyncResource<Log_device>({
	name: "log_device",
	actions: { createOne: {} },
	views: {},
});

export interface Log_error {
	id?: IdValue;
	m?: string;
	st?: string;
	url?: string;
	me?: string;
	udid?: string;
	wdid?: string;
	fv?: string;
	gv?: string;
	t?: ISODateValue;
	uid?: IdValue;
}
defineAsyncResource<Log_error>({
	name: "log_error",
	actions: { createOne: {} },
	views: {},
});

export interface Agt_dai_ly {
	id?: IdValue;
	ten?: string;
	ten_day_du?: string;
	ma?: string;
	loai?: string;
	logo_id?: IdValue;
	doanh_nghiep?: {
		nguoi_dai_dien?: {
			ten?: string;
			can_cuoc?: string;
			dia_chi?: {
				quoc_gia_id?: IdValue;
				tinh_id?: IdValue;
				huyen_id?: IdValue;
				xa_id?: IdValue;
				dia_chi?: string;
			};
			dien_thoai?: string;
			email?: string;
		};
		ma_so_thue?: string;
		dang_ky_kinh_doanh?: string;
		dia_chi?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
		van_phong_giao_dich?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		}[];
		dien_thoai?: string;
		email?: string;
		nguoi_lien_he?: {
			ten?: string;
			can_cuoc?: string;
			dia_chi?: {
				quoc_gia_id?: IdValue;
				tinh_id?: IdValue;
				huyen_id?: IdValue;
				xa_id?: IdValue;
				dia_chi?: string;
			};
			dien_thoai?: string;
			email?: string;
		};
	};
	ca_nhan?: {
		ten?: string;
		can_cuoc?: string;
		dia_chi?: {
			quoc_gia_id?: IdValue;
			tinh_id?: IdValue;
			huyen_id?: IdValue;
			xa_id?: IdValue;
			dia_chi?: string;
		};
		dien_thoai?: string;
		email?: string;
	};
	thong_tin_thanh_toan?: {
		ngan_hang_id?: IdValue;
		so_tai_khoan?: string;
		ten_chu_tai_khoan?: string;
		ten_ngan_hang?: string;
		chi_nhanh?: string;
	};
	chu_ky_thanh_toan?: string;
	loai_dong_tien?: string;
	trang_thai?: string;
	hop_dong_file_ids?: IdValue[];
	tai_khoan?: string;
	nguoi_quan_ly_ids?: IdValue[];
	cau_hinh?: {
		dang_ky_dong_ho?: {
			dung_thu?: boolean;
			so_ngay_dung_thu?: number;
		};
		tao_dai_ly_tinh?: boolean;
		tao_voucher?: boolean;
	};
	voucher_tags?: string[];
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Agt_dai_ly$ViewsResource_tong_doanh_thuView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tong_tienView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tien_chiet_khauView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tien_don_hangView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tien_dai_ly_chua_xac_nhanView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tien_viettoc_chua_xac_nhanView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_tien_co_the_rutView_AgentUserParameters {
	so_ngay: number;
}
export interface Agt_dai_ly$ViewsResource_tien_qua_hanView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_get_voucher_tagsView_SystemAdminParameters {}
export interface Agt_dai_ly$ViewsResource_get_voucher_tagsView_AgentUserParameters {}
export interface Agt_dai_ly$ViewsResource_get_thong_tin_dai_lyView_FamilyUserParameters {
	dong_ho_id: IdValue;
}
export interface Agt_dai_ly$ViewsResource {
	views: {
		tong_doanh_thuAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tong_doanh_thuView_AgentUserParameters
		>;
		tong_tienAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tong_tienView_AgentUserParameters
		>;
		tien_chiet_khauAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_chiet_khauView_AgentUserParameters
		>;
		tien_don_hangAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_don_hangView_AgentUserParameters
		>;
		tien_dai_ly_chua_xac_nhanAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_dai_ly_chua_xac_nhanView_AgentUserParameters
		>;
		tien_viettoc_chua_xac_nhanAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_viettoc_chua_xac_nhanView_AgentUserParameters
		>;
		tien_co_the_rutAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_co_the_rutView_AgentUserParameters
		>;
		tien_qua_hanAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_tien_qua_hanView_AgentUserParameters
		>;
		get_voucher_tagsAsync:
			| MushroomViewFunction<
					any,
					Agt_dai_ly$ViewsResource_get_voucher_tagsView_SystemAdminParameters
			  >
			| MushroomViewFunction<
					any,
					Agt_dai_ly$ViewsResource_get_voucher_tagsView_AgentUserParameters
			  >;
		get_thong_tin_dai_lyAsync: MushroomViewFunction<
			any,
			Agt_dai_ly$ViewsResource_get_thong_tin_dai_lyView_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Agt_dai_ly>({
	name: "agt_dai_ly",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {
		tong_doanh_thu: { clientCache: true },
		tong_tien: { clientCache: true },
		tien_chiet_khau: { clientCache: true },
		tien_don_hang: { clientCache: true },
		tien_dai_ly_chua_xac_nhan: { clientCache: true },
		tien_viettoc_chua_xac_nhan: { clientCache: true },
		tien_co_the_rut: { clientCache: true },
		tien_qua_han: { clientCache: true },
		get_voucher_tags: { clientCache: true },
		get_thong_tin_dai_ly: { clientCache: true },
	},
});

export interface Agt_dai_ly_tinh {
	id?: IdValue;
	dai_ly_id?: IdValue;
	tinh_id?: IdValue;
	cap_id?: IdValue;
	chiet_khau_co_dinh?: boolean;
	chiet_khau?: {
		san_pham_id?: IdValue;
		gia_tri?: number;
	}[];
	chinh_thuc?: boolean;
	hieu_luc?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Agt_dai_ly_tinh>({
	name: "agt_dai_ly_tinh",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Agt_cap {
	id?: IdValue;
	ten?: string;
	mo_ta?: string;
	mau?: string;
	chiet_khau?: {
		san_pham_id?: IdValue;
		gia_tri?: number;
	}[];
	chiet_khau_khach?: boolean;
	tang_cap?: {
		cap_id?: IdValue;
		dieu_kien?: {
			doanh_thu_365?: number;
			so_don_365?: number;
		};
	};
	giam_cap?: {
		cap_id?: IdValue;
		dieu_kien?: {
			doanh_thu_365?: number;
			so_don_365?: number;
		};
	};
	cong_khai?: boolean;
	hieu_luc?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Agt_cap>({
	name: "agt_cap",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Agt_khach_hang {
	id?: IdValue;
	dai_ly_id?: IdValue;
	ten?: string;
	xung_ho?: string;
	dia_chi?: {
		quoc_gia_id?: IdValue;
		tinh_id?: IdValue;
		huyen_id?: IdValue;
		xa_id?: IdValue;
		dia_chi?: string;
	};
	dien_thoai?: string;
	email?: string;
	gan_sao?: boolean;
	tags?: string[];
	lich_su_tu_van?: {
		noi_dung?: string;
		thoi_diem?: ISODateValue;
	}[];
	trang_thai?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Agt_khach_hang$ViewsResource_get_tagsView_AgentUserParameters {}
export interface Agt_khach_hang$ViewsResource {
	views: {
		get_tagsAsync: MushroomViewFunction<
			any,
			Agt_khach_hang$ViewsResource_get_tagsView_AgentUserParameters
		>;
	};
}
defineAsyncResource<Agt_khach_hang>({
	name: "agt_khach_hang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: { get_tags: { clientCache: true } },
});

export interface Prd_goi_dich_vu {
	id?: IdValue;
	thu_tu?: number;
	ten?: string;
	chu_ky_goi?: {
		loai?: string;
		gia_tri?: number;
	};
	danh_sach_chuc_nang?: {
		ma?: string;
		ten?: string;
		gia_tri?: number;
	}[];
	cong_khai?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Prd_goi_dich_vu$CustomMethodResource {
	get_chi_tiet_goi_dich_vuAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
export interface Prd_goi_dich_vu$ViewsResource_get_chi_tiet_goi_dich_vuView_AgentUser_FamilyUserParameters {
	ids: IdValue[];
}
export interface Prd_goi_dich_vu$ViewsResource {
	views: {
		get_chi_tiet_goi_dich_vuAsync: MushroomViewFunction<
			any,
			Prd_goi_dich_vu$ViewsResource_get_chi_tiet_goi_dich_vuView_AgentUser_FamilyUserParameters
		>;
	};
}
defineAsyncResource<Prd_goi_dich_vu>({
	name: "prd_goi_dich_vu",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		_raw_http_method_get_chi_tiet_goi_dich_vu: {},
	},
	views: { get_chi_tiet_goi_dich_vu: { clientCache: true } },
});

export interface Prd_san_pham {
	id?: IdValue;
	ten?: string;
	ma_sp?: string;
	loai?: string;
	mo_ta_ngan?: string;
	mo_ta?: string;
	goi_dich_vu_id?: IdValue;
	gia_niem_yet?: number;
	don_vi?: string;
	dai_ly_ban?: boolean;
	phu_kien?: boolean;
	san_pham_cha_ids?: IdValue[];
	hieu_luc?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Prd_san_pham$CustomMethodResource {
	get_chi_tiet_san_phamAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Prd_san_pham>({
	name: "prd_san_pham",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		_raw_http_method_get_chi_tiet_san_pham: {},
	},
	views: {},
});

export interface Ord_don_hang {
	id?: IdValue;
	ma_don_hang?: string;
	loai_don_hang?: string;
	khoi_tao?: {
		khach_hang_id?: IdValue;
		goi_dich_vu_id?: IdValue;
		thong_tin_goi?: {
			danh_sach_chuc_nang?: {
				ma?: string;
				ten?: string;
				gia_tri?: number;
			}[];
		};
	};
	gia_han?: {
		goi_dich_vu_id?: IdValue;
		thong_tin_goi?: {
			danh_sach_chuc_nang?: {
				ma?: string;
				ten?: string;
				gia_tri?: number;
			}[];
		};
	};
	nang_cap?: {
		goi_dich_vu_id?: IdValue;
		thong_tin_goi?: {
			danh_sach_chuc_nang?: {
				ma?: string;
				ten?: string;
				gia_tri?: number;
			}[];
		};
	};
	in?: {
		ban_in_id?: IdValue;
		ghi_chu?: string;
	};
	dong_ho?: {
		dong_ho_id?: IdValue;
		ten_dong_ho?: string;
	};
	tinh_id?: IdValue;
	dai_ly_id?: IdValue;
	dai_ly_tinh_id?: IdValue;
	danh_sach_san_pham?: {
		san_pham_id?: IdValue;
		ten_san_pham?: string;
		goi_dich_vu_id?: IdValue;
		ma_san_pham?: string;
		mo_ta_ngan?: string;
		mo_ta?: string;
		so_luong?: number;
		don_vi?: string;
		gia_niem_yet?: number;
		chiet_khau_dai_ly?: number;
		chiet_khau_khach_dai_ly?: number;
		chiet_khau_khach_viettoc?: number;
		vouchers?: {
			voucher_id?: IdValue;
			ma?: string;
			giam_gia?: number;
		}[];
		don_gia?: number;
		tong_tien?: number;
		giam_gia?: number;
	}[];
	chiet_khau_dai_ly?: number;
	chiet_khau_khach_dai_ly?: number;
	chiet_khau_khach_viettoc?: number;
	vouchers?: {
		voucher_id?: IdValue;
		ma?: string;
	}[];
	tong_tien?: number;
	giam_gia?: number;
	vat?: number;
	thanh_tien?: number;
	trang_thai_thanh_toan?: string;
	nguoi_thanh_toan?: {
		ho_ten?: string;
		node_id?: IdValue;
	};
	anh_bien_lai_id?: IdValue;
	trang_thai?: string;
	lich_su?: {
		thoi_diem?: ISODateValue;
		trang_thai?: string;
		nguoi_chuyen_id?: IdValue;
	}[];
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Ord_don_hang$CustomMethodResource {
	kiem_tra_vouchersAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
	qr_thanh_toanAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
export interface Ord_don_hang$ViewsResource_danh_sach_san_phamView_SystemAdminParameters {}
export interface Ord_don_hang$ViewsResource_danh_sach_san_phamView_AgentUserParameters {}
export interface Ord_don_hang$ViewsResource {
	views: {
		danh_sach_san_phamAsync:
			| MushroomViewFunction<
					any,
					Ord_don_hang$ViewsResource_danh_sach_san_phamView_SystemAdminParameters
			  >
			| MushroomViewFunction<
					any,
					Ord_don_hang$ViewsResource_danh_sach_san_phamView_AgentUserParameters
			  >;
	};
}
defineAsyncResource<Ord_don_hang>({
	name: "ord_don_hang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
		_raw_http_method_kiem_tra_vouchers: {},
		_raw_http_method_qr_thanh_toan: {},
	},
	views: { danh_sach_san_pham: { clientCache: true } },
});

export interface Ord_voucher {
	id?: IdValue;
	dai_ly_id?: IdValue;
	dai_ly_tinh_ids?: IdValue[];
	ma?: string;
	ten?: string;
	loai?: string;
	mo_ta?: string;
	gia_tri?: number;
	kieu_gia_tri?: string;
	dieu_kien?: {}[];
	hieu_luc?: {
		tu?: ISODateValue;
		den?: ISODateValue;
	};
	dong_ho_ids?: IdValue[];
	san_pham_ids?: IdValue[];
	don_hang_ids?: IdValue[];
	tang?: string[];
	khoi_tao?: boolean;
	ap_dung_chung?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Ord_voucher>({
	name: "ord_voucher",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		updatePartially: {},
	},
	views: {},
});

export interface Pmt_don_hang {
	id?: IdValue;
	don_hang_id?: IdValue;
	trang_thai?: string;
	so_tien?: number;
	vat?: number;
	dai_ly_xac_nhan?: boolean;
	vt_xac_nhan?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Pmt_don_hang>({
	name: "pmt_don_hang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Pmt_chiet_khau_dai_ly {
	id?: IdValue;
	don_hang_id?: IdValue;
	dai_ly_id?: IdValue;
	so_tien?: number;
	vat?: number;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Pmt_chiet_khau_dai_ly>({
	name: "pmt_chiet_khau_dai_ly",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Pmt_dai_ly {
	id?: IdValue;
	dai_ly_id?: IdValue;
	so_tien?: number;
	vat?: number;
	danh_sach_don_hang?: {
		don_hang_id?: IdValue;
		ma_don_hang?: string;
	}[];
	ky_thanh_toan?: {
		thang?: number;
		nam?: number;
	};
	noi_dung?: string;
	file_dinh_kem?: IdValue[];
	dai_ly_xac_nhan?: boolean;
	vt_xac_nhan?: boolean;
	ben_thanh_toan?: string;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
export interface Pmt_dai_ly$CustomMethodResource {
	xac_nhan_thanh_toanAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
export interface Pmt_dai_ly$ViewsResource_get_ky_thanh_toanView_AgentUserParameters {
	fromMonth?: number;
	toMonth?: number;
	fromYear?: number;
	toYear?: number;
}
export interface Pmt_dai_ly$ViewsResource {
	views: {
		get_ky_thanh_toanAsync: MushroomViewFunction<
			any,
			Pmt_dai_ly$ViewsResource_get_ky_thanh_toanView_AgentUserParameters
		>;
	};
}
defineAsyncResource<Pmt_dai_ly>({
	name: "pmt_dai_ly",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
		_raw_http_method_xac_nhan_thanh_toan: {},
	},
	views: { get_ky_thanh_toan: { clientCache: true } },
});

export interface Pmt_ngan_hang {
	id?: IdValue;
	ten?: string;
	ten_ngan?: string;
	logo_id?: IdValue;
	ma?: string;
	bin_code?: number;
	swift_code?: string;
	mobile_apps?: {
		loai?: string;
		logo?: string;
		ten?: string;
		so_luong_thang?: number;
		thoi_diem_cap_nhat?: ISODateValue;
	}[];
	thoi_diem_cap_nhat?: ISODateValue;
	nguon_id?: string;
}
defineAsyncResource<Pmt_ngan_hang>({
	name: "pmt_ngan_hang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
	},
	views: {},
});

export interface Pms_chuc_nang {
	id?: IdValue;
	ma?: string;
	ten?: string;
	thu_tu?: number;
	tuy_bien_hien_thi?: {
		gia_tri?: number;
		gia_tri_hien_thi?: string;
	}[];
	kieu_du_lieu?: string;
	nhom?: string;
	don_vi?: string;
	hien_thi?: boolean;
	thoi_diem_tao?: ISODateValue;
	nguoi_tao_id?: IdValue;
	thoi_diem_cap_nhat?: ISODateValue;
	nguoi_cap_nhat_id?: IdValue;
}
defineAsyncResource<Pms_chuc_nang>({
	name: "pms_chuc_nang",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		createOne: {},
	},
	views: {},
});

export interface Viettoc_file {
	id?: IdValue;
	name?: string;
	contentType?: string;
	createdDate?: ISODateValue;
	extension?: string;
	length?: number;
	type?: string;
	lastModified?: ISODateValue;
	md5?: string;
	creatorId?: IdValue;
	fileId?: IdValue;
	tags?: string[];
	trace?: {
		editToken?: string;
		collection?: string;
		field?: string;
		docId?: IdValue;
		ownerId?: IdValue;
	};
}
export interface Viettoc_file$CustomMethodResource {
	setDocIdAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Viettoc_file>({
	name: "viettoc_file",
	actions: { _raw_http_method_setDocId: {} },
	views: {},
});

export interface Sto_file {
	id?: IdValue;
	name?: string;
	contentType?: string;
	createdDate?: ISODateValue;
	extension?: string;
	length?: number;
	type?: string;
	lastModified?: ISODateValue;
	md5?: string;
	creatorId?: IdValue;
	fileId?: IdValue;
	tags?: string[];
	trace?: {
		editToken?: string;
		collection?: string;
		field?: string;
		docId?: IdValue;
		ownerId?: IdValue;
	};
}
export interface Sto_file$CustomMethodResource {
	setDocIdAsync: (
		rawRequest: MushroomRequest
	) => Promise<MushroomResponse<any>>;
}
defineAsyncResource<Sto_file>({
	name: "sto_file",
	actions: {
		findMany: { clientCache: true, includeTotal: true },
		findById: { clientCache: true },
		_raw_http_method_setDocId: {},
	},
	views: {},
});

mushroom.$using("https://api.viettoc.vn/api/viettoc/v1/");

(function () {
	AuthExtension.useAuth(AuthExtension.AuthLocalStorage, mushroom);
	FileExtension.useFile(mushroom);
})();

export interface IApi extends IMushroom {
	dang_ky: MushroomResourceBase &
		MushroomListResource<Dang_ky> &
		MushroomFindByIdResource<Dang_ky> &
		MushroomPartialUpdateResource<Dang_ky> &
		MushroomCreateResource<Dang_ky> &
		Dang_ky$ViewsResource;
	dang_ky_phan_hoi: MushroomResourceBase &
		MushroomListResource<Dang_ky_phan_hoi> &
		MushroomFindByIdResource<Dang_ky_phan_hoi> &
		MushroomCreateResource<Dang_ky_phan_hoi>;
	dang_ky_ghi_chu: MushroomResourceBase &
		MushroomListResource<Dang_ky_ghi_chu> &
		MushroomFindByIdResource<Dang_ky_ghi_chu> &
		MushroomCreateResource<Dang_ky_ghi_chu>;
	dong_ho: MushroomResourceBase &
		MushroomListResource<Dong_ho> &
		MushroomFindByIdResource<Dong_ho> &
		MushroomPartialUpdateResource<Dong_ho> &
		Dong_ho$CustomMethodResource &
		Dong_ho$ViewsResource;
	dong_ho_ghi_chu: MushroomResourceBase &
		MushroomListResource<Dong_ho_ghi_chu> &
		MushroomFindByIdResource<Dong_ho_ghi_chu> &
		MushroomCreateResource<Dong_ho_ghi_chu>;
	thue_bao: MushroomResourceBase &
		MushroomListResource<Thue_bao> &
		MushroomFindByIdResource<Thue_bao> &
		MushroomPartialUpdateResource<Thue_bao>;
	nguoi_dung: MushroomResourceBase &
		MushroomListResource<Nguoi_dung> &
		MushroomFindByIdResource<Nguoi_dung> &
		MushroomPartialUpdateResource<Nguoi_dung> &
		MushroomDeleteResource &
		Nguoi_dung$CustomMethodResource;
	nguoi_dung_cau_hinh: MushroomResourceBase &
		MushroomListResource<Nguoi_dung_cau_hinh> &
		MushroomFindByIdResource<Nguoi_dung_cau_hinh> &
		MushroomCreateResource<Nguoi_dung_cau_hinh> &
		MushroomPartialUpdateResource<Nguoi_dung_cau_hinh>;
	user: MushroomResourceBase &
		MushroomListResource<User> &
		MushroomFindByIdResource<User> &
		MushroomCreateResource<User> &
		MushroomPartialUpdateResource<User>;
	profile: MushroomResourceBase &
		MushroomFindByIdResource<Profile> &
		MushroomPartialUpdateResource<Profile>;
	phien_kich_hoat: MushroomResourceBase &
		MushroomListResource<Phien_kich_hoat> &
		MushroomFindByIdResource<Phien_kich_hoat> &
		MushroomPartialUpdateResource<Phien_kich_hoat> &
		MushroomCreateResource<Phien_kich_hoat> &
		MushroomDeleteResource &
		Phien_kich_hoat$CustomMethodResource;
	phien_kich_hoat_3: MushroomResourceBase &
		MushroomFindByIdResource<Phien_kich_hoat_3> &
		Phien_kich_hoat_3$CustomMethodResource;
	thiet_bi_kich_hoat: MushroomResourceBase &
		Thiet_bi_kich_hoat$CustomMethodResource;
	phien_pc: MushroomResourceBase & Phien_pc$CustomMethodResource;
	node_gia_pha: MushroomResourceBase &
		MushroomListResource<Node_gia_pha> &
		MushroomFindByIdResource<Node_gia_pha> &
		MushroomPartialUpdateResource<Node_gia_pha> &
		Node_gia_pha$CustomMethodResource;
	node_gia_pha_request_field: MushroomResourceBase &
		MushroomListResource<Node_gia_pha_request_field> &
		MushroomFindByIdResource<Node_gia_pha_request_field> &
		MushroomCreateResource<Node_gia_pha_request_field> &
		MushroomPartialUpdateResource<Node_gia_pha_request_field> &
		MushroomDeleteResource;
	su_kien: MushroomResourceBase &
		MushroomListResource<Su_kien> &
		MushroomFindByIdResource<Su_kien> &
		MushroomCreateResource<Su_kien> &
		MushroomPartialUpdateResource<Su_kien>;
	album: MushroomResourceBase &
		MushroomListResource<Album> &
		MushroomFindByIdResource<Album> &
		MushroomCreateResource<Album> &
		MushroomPartialUpdateResource<Album>;
	anh_album: MushroomResourceBase &
		MushroomListResource<Anh_album> &
		MushroomFindByIdResource<Anh_album> &
		MushroomCreateResource<Anh_album> &
		MushroomPartialUpdateResource<Anh_album> &
		Anh_album$CustomMethodResource &
		Anh_album$ViewsResource;
	bang_vang: MushroomResourceBase &
		MushroomListResource<Bang_vang> &
		MushroomFindByIdResource<Bang_vang> &
		MushroomCreateResource<Bang_vang> &
		MushroomPartialUpdateResource<Bang_vang>;
	tin_khuyen_hoc: MushroomResourceBase &
		MushroomListResource<Tin_khuyen_hoc> &
		MushroomFindByIdResource<Tin_khuyen_hoc> &
		MushroomCreateResource<Tin_khuyen_hoc> &
		MushroomPartialUpdateResource<Tin_khuyen_hoc> &
		MushroomDeleteResource;
	quy_khuyen_hoc: MushroomResourceBase &
		MushroomListResource<Quy_khuyen_hoc> &
		MushroomFindByIdResource<Quy_khuyen_hoc> &
		MushroomCreateResource<Quy_khuyen_hoc> &
		Quy_khuyen_hoc$ViewsResource;
	cong_duc: MushroomResourceBase &
		MushroomListResource<Cong_duc> &
		MushroomFindByIdResource<Cong_duc> &
		MushroomCreateResource<Cong_duc> &
		MushroomPartialUpdateResource<Cong_duc> &
		MushroomDeleteResource &
		Cong_duc$ViewsResource;
	cong_duc_cam_ket: MushroomResourceBase &
		MushroomListResource<Cong_duc_cam_ket> &
		MushroomFindByIdResource<Cong_duc_cam_ket> &
		MushroomCreateResource<Cong_duc_cam_ket>;
	cong_duc_dong_gop: MushroomResourceBase &
		MushroomListResource<Cong_duc_dong_gop> &
		MushroomFindByIdResource<Cong_duc_dong_gop> &
		MushroomCreateResource<Cong_duc_dong_gop> &
		MushroomPartialUpdateResource<Cong_duc_dong_gop>;
	vinh_hang_vien: MushroomResourceBase &
		MushroomPartialUpdateResource<Vinh_hang_vien> &
		MushroomCreateResource<Vinh_hang_vien> &
		Vinh_hang_vien$CustomMethodResource;
	don_hang: MushroomResourceBase &
		MushroomListResource<Don_hang> &
		MushroomFindByIdResource<Don_hang> &
		MushroomCreateResource<Don_hang> &
		MushroomPartialUpdateResource<Don_hang>;
	phuong_thuc_thanh_toan: MushroomResourceBase &
		MushroomListResource<Phuong_thuc_thanh_toan> &
		MushroomFindByIdResource<Phuong_thuc_thanh_toan>;
	thanh_toan: MushroomResourceBase &
		MushroomListResource<Thanh_toan> &
		MushroomFindByIdResource<Thanh_toan>;
	goi_dich_vu: MushroomResourceBase &
		MushroomListResource<Goi_dich_vu> &
		MushroomFindByIdResource<Goi_dich_vu>;
	tham_so_goi_dich_vu: MushroomResourceBase &
		MushroomListResource<Tham_so_goi_dich_vu> &
		MushroomFindByIdResource<Tham_so_goi_dich_vu> &
		MushroomCreateResource<Tham_so_goi_dich_vu> &
		MushroomPartialUpdateResource<Tham_so_goi_dich_vu> &
		MushroomDeleteResource;
	cau_hoi_thuong_gap: MushroomResourceBase &
		MushroomListResource<Cau_hoi_thuong_gap> &
		MushroomFindByIdResource<Cau_hoi_thuong_gap> &
		MushroomCreateResource<Cau_hoi_thuong_gap> &
		MushroomPartialUpdateResource<Cau_hoi_thuong_gap> &
		MushroomDeleteResource;
	chinh_sach: MushroomResourceBase &
		MushroomListResource<Chinh_sach> &
		MushroomFindByIdResource<Chinh_sach> &
		MushroomCreateResource<Chinh_sach> &
		MushroomPartialUpdateResource<Chinh_sach> &
		MushroomDeleteResource;
	thong_ke: MushroomResourceBase & Thong_ke$ViewsResource;
	ldp_cau_hinh: MushroomResourceBase &
		MushroomListResource<Ldp_cau_hinh> &
		MushroomFindByIdResource<Ldp_cau_hinh> &
		MushroomCreateResource<Ldp_cau_hinh> &
		MushroomPartialUpdateResource<Ldp_cau_hinh> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	ldp_slide: MushroomResourceBase &
		MushroomListResource<Ldp_slide> &
		MushroomFindByIdResource<Ldp_slide> &
		MushroomCreateResource<Ldp_slide> &
		MushroomPartialUpdateResource<Ldp_slide> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	ldp_danh_gia: MushroomResourceBase &
		MushroomListResource<Ldp_danh_gia> &
		MushroomFindByIdResource<Ldp_danh_gia> &
		MushroomCreateResource<Ldp_danh_gia> &
		MushroomPartialUpdateResource<Ldp_danh_gia> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	ldp_loi_nhan: MushroomResourceBase &
		MushroomListResource<Ldp_loi_nhan> &
		MushroomFindByIdResource<Ldp_loi_nhan> &
		MushroomCreateResource<Ldp_loi_nhan> &
		MushroomPartialUpdateResource<Ldp_loi_nhan> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	ldp_loi_nhan_phan_hoi: MushroomResourceBase &
		MushroomListResource<Ldp_loi_nhan_phan_hoi> &
		MushroomFindByIdResource<Ldp_loi_nhan_phan_hoi> &
		MushroomCreateResource<Ldp_loi_nhan_phan_hoi>;
	ldp_thong_ke: MushroomResourceBase & Ldp_thong_ke$ViewsResource;
	ldp_bai_viet: MushroomResourceBase &
		MushroomListResource<Ldp_bai_viet> &
		MushroomFindByIdResource<Ldp_bai_viet> &
		MushroomCreateResource<Ldp_bai_viet> &
		MushroomPartialUpdateResource<Ldp_bai_viet> &
		MushroomDeleteResource &
		Ldp_bai_viet$ViewsResource;
	quoc_gia: MushroomResourceBase &
		MushroomListResource<Quoc_gia> &
		MushroomFindByIdResource<Quoc_gia>;
	province: MushroomResourceBase &
		MushroomListResource<Province> &
		MushroomFindByIdResource<Province>;
	district: MushroomResourceBase &
		MushroomListResource<District> &
		MushroomFindByIdResource<District>;
	commune: MushroomResourceBase &
		MushroomListResource<Commune> &
		MushroomFindByIdResource<Commune>;
	ton_giao: MushroomResourceBase &
		MushroomListResource<Ton_giao> &
		MushroomFindByIdResource<Ton_giao>;
	notification: MushroomResourceBase &
		MushroomListResource<Notification> &
		MushroomFindByIdResource<Notification> &
		MushroomPartialUpdateResource<Notification> &
		MushroomDeleteResource &
		Notification$CustomMethodResource;
	notification_queue: MushroomResourceBase &
		MushroomListResource<Notification_queue> &
		MushroomFindByIdResource<Notification_queue> &
		MushroomCreateResource<Notification_queue> &
		MushroomPartialUpdateResource<Notification_queue> &
		MushroomDeleteResource;
	firebase: MushroomResourceBase &
		MushroomListResource<Firebase> &
		MushroomFindByIdResource<Firebase> &
		MushroomCreateResource<Firebase> &
		MushroomPartialUpdateResource<Firebase> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	mail_template: MushroomResourceBase &
		MushroomListResource<Mail_template> &
		MushroomFindByIdResource<Mail_template> &
		MushroomCreateResource<Mail_template> &
		MushroomPartialUpdateResource<Mail_template> &
		MushroomDeleteResource;
	cau_hinh_thong_bao: MushroomResourceBase &
		MushroomListResource<Cau_hinh_thong_bao> &
		MushroomFindByIdResource<Cau_hinh_thong_bao> &
		MushroomCreateResource<Cau_hinh_thong_bao> &
		MushroomPartialUpdateResource<Cau_hinh_thong_bao> &
		MushroomDeleteResource;
	cau_hinh_he_thong: MushroomResourceBase &
		MushroomListResource<Cau_hinh_he_thong> &
		MushroomFindByIdResource<Cau_hinh_he_thong> &
		MushroomCreateResource<Cau_hinh_he_thong> &
		MushroomPartialUpdateResource<Cau_hinh_he_thong> &
		MushroomDeleteResource &
		MushroomBatchDeleteResource;
	ban_in: MushroomResourceBase &
		MushroomListResource<Ban_in> &
		MushroomFindByIdResource<Ban_in> &
		MushroomCreateResource<Ban_in> &
		MushroomPartialUpdateResource<Ban_in> &
		MushroomDeleteResource;
	qr_scan: MushroomResourceBase &
		MushroomListResource<Qr_scan> &
		MushroomFindByIdResource<Qr_scan> &
		MushroomCreateResource<Qr_scan> &
		MushroomPartialUpdateResource<Qr_scan> &
		MushroomDeleteResource &
		Qr_scan$CustomMethodResource;
	version: MushroomResourceBase &
		MushroomListResource<Version> &
		MushroomFindByIdResource<Version> &
		MushroomCreateResource<Version> &
		MushroomPartialUpdateResource<Version> &
		MushroomDeleteResource;
	log_action: MushroomResourceBase & MushroomCreateResource<Log_action>;
	log_device: MushroomResourceBase & MushroomCreateResource<Log_device>;
	log_error: MushroomResourceBase & MushroomCreateResource<Log_error>;
	agt_dai_ly: MushroomResourceBase &
		MushroomListResource<Agt_dai_ly> &
		MushroomFindByIdResource<Agt_dai_ly> &
		MushroomCreateResource<Agt_dai_ly> &
		MushroomPartialUpdateResource<Agt_dai_ly> &
		Agt_dai_ly$ViewsResource;
	agt_dai_ly_tinh: MushroomResourceBase &
		MushroomListResource<Agt_dai_ly_tinh> &
		MushroomFindByIdResource<Agt_dai_ly_tinh> &
		MushroomCreateResource<Agt_dai_ly_tinh> &
		MushroomPartialUpdateResource<Agt_dai_ly_tinh>;
	agt_cap: MushroomResourceBase &
		MushroomListResource<Agt_cap> &
		MushroomFindByIdResource<Agt_cap> &
		MushroomCreateResource<Agt_cap> &
		MushroomPartialUpdateResource<Agt_cap>;
	agt_khach_hang: MushroomResourceBase &
		MushroomListResource<Agt_khach_hang> &
		MushroomFindByIdResource<Agt_khach_hang> &
		MushroomCreateResource<Agt_khach_hang> &
		MushroomPartialUpdateResource<Agt_khach_hang> &
		Agt_khach_hang$ViewsResource;
	prd_goi_dich_vu: MushroomResourceBase &
		MushroomListResource<Prd_goi_dich_vu> &
		MushroomFindByIdResource<Prd_goi_dich_vu> &
		MushroomCreateResource<Prd_goi_dich_vu> &
		MushroomPartialUpdateResource<Prd_goi_dich_vu> &
		Prd_goi_dich_vu$CustomMethodResource &
		Prd_goi_dich_vu$ViewsResource;
	prd_san_pham: MushroomResourceBase &
		MushroomListResource<Prd_san_pham> &
		MushroomFindByIdResource<Prd_san_pham> &
		MushroomCreateResource<Prd_san_pham> &
		MushroomPartialUpdateResource<Prd_san_pham> &
		Prd_san_pham$CustomMethodResource;
	ord_don_hang: MushroomResourceBase &
		MushroomListResource<Ord_don_hang> &
		MushroomFindByIdResource<Ord_don_hang> &
		MushroomCreateResource<Ord_don_hang> &
		MushroomPartialUpdateResource<Ord_don_hang> &
		Ord_don_hang$CustomMethodResource &
		Ord_don_hang$ViewsResource;
	ord_voucher: MushroomResourceBase &
		MushroomListResource<Ord_voucher> &
		MushroomFindByIdResource<Ord_voucher> &
		MushroomCreateResource<Ord_voucher> &
		MushroomPartialUpdateResource<Ord_voucher>;
	pmt_don_hang: MushroomResourceBase &
		MushroomListResource<Pmt_don_hang> &
		MushroomFindByIdResource<Pmt_don_hang>;
	pmt_chiet_khau_dai_ly: MushroomResourceBase &
		MushroomListResource<Pmt_chiet_khau_dai_ly> &
		MushroomFindByIdResource<Pmt_chiet_khau_dai_ly>;
	pmt_dai_ly: MushroomResourceBase &
		MushroomListResource<Pmt_dai_ly> &
		MushroomFindByIdResource<Pmt_dai_ly> &
		MushroomCreateResource<Pmt_dai_ly> &
		Pmt_dai_ly$CustomMethodResource &
		Pmt_dai_ly$ViewsResource;
	pmt_ngan_hang: MushroomResourceBase &
		MushroomListResource<Pmt_ngan_hang> &
		MushroomFindByIdResource<Pmt_ngan_hang>;
	pms_chuc_nang: MushroomResourceBase &
		MushroomListResource<Pms_chuc_nang> &
		MushroomFindByIdResource<Pms_chuc_nang> &
		MushroomCreateResource<Pms_chuc_nang>;
	viettoc_file: MushroomResourceBase & Viettoc_file$CustomMethodResource;
	sto_file: MushroomResourceBase &
		MushroomListResource<Sto_file> &
		MushroomFindByIdResource<Sto_file> &
		Sto_file$CustomMethodResource;
}

export type Api = AuthExtension.IMushroomAuth &
	FileExtension.IMushroomFile &
	IApi;

export default mushroom as Api;
