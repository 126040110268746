import { combineReducers, configureStore } from "@reduxjs/toolkit";

const combinedReducer = combineReducers({});

const rootReducer = (state: any, action: any) => {
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
