import { render } from "react-dom";
import { Provider } from "react-redux";

import "assets/css/app.scss";
import { store } from "store/store";
import App from "pages/App";
import mushroom from "viettoc-api";

// FOR CI/CD PURPOSE
const rootApiUrl = (window as any).__root_api_url__;
if (rootApiUrl && rootApiUrl !== "###_ROOT_API_URL_###") {
	mushroom.$using(rootApiUrl);
}

const ViettocApp = () => {
	return (
		<Provider store={store}>
			<App />
		</Provider>
	);
};

render(<ViettocApp />, document.getElementById("root"));
