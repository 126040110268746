import React from "react";
import { IRoute } from "types/IRoute";

const lazyComponent = (path: string, delay: number = 300) =>
	Promise.all([
		import(`pages${path}.tsx`),
		new Promise((resolve) => setTimeout(resolve, delay)), // ensures minimal delay
	]).then(([module]) => module);

const GiaPha = React.lazy(() => lazyComponent("/GiaPha"));

const routes: IRoute[] = [
	{
		path: "/giapha",
		exact: true,
		component: GiaPha,
	},
];

export default routes;
