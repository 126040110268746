import { Route } from "react-router-dom";

export const RouteWithSubRoutes = (route: any) => {
	return (
		<Route
			path={route.path}
			render={(props) => (
				<route.component {...props} routes={route.routes} />
			)}
		/>
	);
};
